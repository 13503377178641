<template>
  <div class="main" :style="{minWidth:ScaleUtil.siteMinWidth()+'px'}">
    <router-view/>
  </div>
</template>
<script lang="ts" setup>
import {onBeforeUnmount, onMounted, provide, ref} from "vue";
import * as ScaleUtil from '@/utils/ScaleUtil'

const isMobile = ref(ScaleUtil.isMobile())
provide("IS_MOBILE", isMobile);

const resizeHandler = () => {
  document.getElementsByTagName('html')[0].style.fontSize = ScaleUtil.getRootFontSize() + 'PX'
}

onMounted(() => {
  resizeHandler()
  window.addEventListener('resize', resizeHandler)
})

onBeforeUnmount(() => {
  window.removeEventListener('resize', resizeHandler)
})

onMounted(() => {
  document.dispatchEvent(new Event('render-event'))
})

</script>
<style lang="less" scoped>
.main {
  height: 100%;
  width: 100%;
}
</style>
<style>
@font-face {
  font-family: 'DingTalk-JinBuTi';
  src: url("../src/assets/font/DingTalk_JinBuTi_Regular.woff2");
}

@font-face {
  font-family: 'OPPOSans-M';
  src: url("../src/assets/font/OPPOSans-M.woff2");
}

@font-face {
  font-family: 'OPPOSans-M';
  src: url("../src/assets/font/OPPOSans-M.woff2");
}

@font-face {
  font-family: 'OPPOSans';
  src: url("../src/assets/font/OPPOSans-M.woff2");
}

* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  font-family: PingFangSC, OPPOSans, "Microsoft YaHei", sans-serif;
  scroll-behavior: smooth;
}

body, html {
  font-size: 12px;
  height: 100%;
  width: 100%;
}
body{
  overflow-y: scroll;
}
a {
  color: inherit;
  text-decoration: none;
  outline: none !important;
}

a, img, button, input, textarea, div {
  -webkit-tap-highlight-color: rgba(255, 255, 255, 0);
}
</style>

<template>
  <Transition name="down">
    <div class="simple-toast" :class="['simple-toast--'+type,{'simple-toast--mobile':isMobile()}]" v-show="visible">
      {{ text }}
    </div>
  </Transition>
</template>
<script lang="ts">
import {ref, onMounted} from "vue";

export default {
  name: "SimpleToast",
  props: {
    text: {
      type: String,
      default: "",
    },
    duration: {
      type: Number,
      default: 0,
    },
    type: {
      type: String,
      default: "success",
    },
  },
  setup(props: any) {
    const isMobile = () => {
      const info = navigator.userAgent;
      const agents = ["Android", "iPhone", "SymbianOS", "Windows Phone", "iPod", "iPad"];
      for (let i = 0; i < agents.length; i++) {
        if (info.indexOf(agents[i]) >= 0) {
          return true;
        }
      }
      return false;
    }
    const visible = ref(false);
    onMounted(() => {
      visible.value = true;
      setTimeout(() => {
        visible.value = false;
      }, props.duration - 800)
    });
    return {
      visible,
      isMobile
    };
  },
};
</script>
<style scoped lang="less">
.simple-toast {
  max-width: 95%;
  min-width: 280px;
  min-height: 56px;
  position: fixed;
  z-index: 10000;
  left: 50%;
  top: 12%;
  box-shadow: 10px 10px 20px rgba(0, 0, 0, 0.1);
  color: #fff;
  border-radius: 5px;
  text-align: center;
  display: flex;
  border: 1px solid gray;
  justify-content: center;
  align-items: center;
  font-size: 25px;
  padding: 8px;
  transform: translateX(-50%) translateY(-50%);
  white-space: pre;

  &--success {
    color: #67c23a;
    background-color: #f0f9eb;
    border-color: #e1f3d8;
  }

  &--error {
    color: #f56c6c;
    background-color: #fef0f0;
    border-color: #fde2e2;
  }
  &--mobile{
    font-size: 4vw !important;
    padding: 2vw !important;
    top: 40% !important;
  }
}

.down-enter-from, .down-leave-to {
  top: 0;
  opacity: 0;
}

.down-enter-active, .down-leave-active {
  transition: all 0.8s ease-out;
}

.down-enter-to, .down-leave-from {
  opacity: 1;
  top: 12%;
}


</style>

import { defineComponent as _defineComponent } from 'vue'
import { resolveComponent as _resolveComponent, createVNode as _createVNode, normalizeStyle as _normalizeStyle, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

import {onBeforeUnmount, onMounted, provide, ref} from "vue";
import * as ScaleUtil from '@/utils/ScaleUtil'


export default /*@__PURE__*/_defineComponent({
  __name: 'App',
  setup(__props) {

const isMobile = ref(ScaleUtil.isMobile())
provide("IS_MOBILE", isMobile);

const resizeHandler = () => {
  document.getElementsByTagName('html')[0].style.fontSize = ScaleUtil.getRootFontSize() + 'PX'
}

onMounted(() => {
  resizeHandler()
  window.addEventListener('resize', resizeHandler)
})

onBeforeUnmount(() => {
  window.removeEventListener('resize', resizeHandler)
})

onMounted(() => {
  document.dispatchEvent(new Event('render-event'))
})


return (_ctx: any,_cache: any) => {
  const _component_router_view = _resolveComponent("router-view")!

  return (_openBlock(), _createElementBlock("div", {
    class: "main",
    style: _normalizeStyle({minWidth:ScaleUtil.siteMinWidth()+'px'})
  }, [
    _createVNode(_component_router_view)
  ], 4))
}
}

})